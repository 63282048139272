import React from 'react';
import { useState, useEffect, useRef} from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { configData } from "../config";
import './classes/OrderDetailsPage.css';
import ReactToPrint from 'react-to-print';									  
import OrderDetailsPrint from './OrderDetailsPrint';
import OrderDetailsSend from './OrderDetailsSend';
										 

const OrderDetailsPage = () => {
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [isSendReceiptModalOpen, setIsSendReceiptModalOpen] = useState(false);															  
	const printRef = useRef();						
  const sendemailRef = useRef();
  const[success,setSuccess] = useState({});
  const app_url = configData.APP_URL;
  const[userOrderDetails,setUserOrderDetails] = useState({});
  const[shippingDetails,setShippingDetails] = useState({});
  const[billingDetails,setBillingDetails] = useState({});
  const[orderProducts,setOrderProducts] = useState([]);
  const[discounts,setDiscounts] = useState([]);
  const[survey,setSurvey] = useState([]);
  const[total,setOrderTotal] = useState([]);
  const[confbadge,setConfBadge] = useState([]);
  const {uid} = useParams();
  const {order_id} = useParams();

  const handlePrint = () => {
    setIsPrintModalOpen(true);
  };
  const handleSendReceipt = () => { 
    setIsSendReceiptModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsPrintModalOpen(false);
    setIsSendReceiptModalOpen(false); 
  };
  const handleSendEmail = async event => { 
  const postData = {
    'order_id': order_id,
    'uid':uid
}
const response = await fetch(app_url+'smwwreceipt/sendreceiptemail', {
    method: 'POST',
    body: JSON.stringify(postData),
    headers: {
      'Content-Type': 'application/json',
    },
    });
    const data = await response.json();
    if(data.data.msg === "Success") {
      setIsSendReceiptModalOpen(false);
      setSuccess(data.data.result);
      window.scrollTo(0, 0);
  }
  };

  useEffect(() => {
   
    retrieveOrderDetails();
    
  },[]);
  async function retrieveOrderDetails() {
    
    const response = await fetch(
      app_url+'orderdetails/getorderdetails?uid='+uid+"&order_id="+order_id,
      {
        method: "GET",
  
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const responsedata = await response.json();
    if(responsedata.data.msg === "Success") {
    
      setUserOrderDetails(responsedata.data.result);
      setShippingDetails(responsedata.data.result['shipping']);
      setBillingDetails(responsedata.data.result['billing']);
      setOrderProducts(responsedata.data.result['product']);
      setDiscounts(responsedata.data.result['discounts']);
      setOrderTotal(responsedata.data.result['order_total']);
      setSurvey(responsedata.data.survey);
      setConfBadge(responsedata.data.conf_badge);
    } 

  }

  return (
    <div className="order-details-page-container">
         {success.length > 0 && (
          <>
           {success.map((item, index) => {
             return <>
            <div className="set-message-done">
            <span className="cart-message-icon message-icon"><i className="fa fa-check-circle"></i></span> {item}
          </div></>
          
        })}  
          </>
        )}
      <div className="order-details-page-content">
        <div className="order-details-page-main">
          <div className="order-details-page-header">
            <div className="order-details-page-left-header">
              <a href={"/user/"+uid+"/myorders"}>← Back</a>
              
            </div>
            <div className="order-details-page-right-header">
              <a href="#" onClick={handleSendReceipt}>Send Receipt</a> {/* Update to call handleSendReceipt */}
              <a href="#" onClick={handlePrint}>Print</a>
            </div>
          </div>
          <div className="order-details-page-section">
            <div className='od-table-panel'>
              <h2>Order Details</h2>
              <div className='panelbody'>
                <table className="order-details-page-table">
                  <thead>
                    <tr className="order-details-page-odd">
                      <th width="60%">Title</th>
                      <th width="15%">Unit Price</th>
                      <th width="15%">Price (Discount Applied)</th>
                    </tr>
                  </thead>
                  <tbody>
                   
                    {orderProducts.map((item, index) => (

                      <tr className="order-details-page-even">
                      <td>{item.product_title}</td>
                      <td>${item.unit_amount}</td>
                    
                      <td>${item.calculated_amount}</td>
                      
										
                    </tr>
                    ))}
                    {discounts &&<>
                    {discounts.map((item, index) => (
                      <tr className="order-details-page-odd">
                        <td className='od-table-totals' colSpan="2">{item.name}</td>
                        
                        <td>{item.amount}</td>
                      </tr>
                     ))}
                     </> 
                    }
                    <tr className="order-details-page-odd">
                        <td className='od-table-totals' colSpan="2">Total</td>
                        
                        <td>{total}</td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="order-details-page-info">
            <div className="order-details-page-box">
              <h3>Shipping Information</h3>
              <div className='od-ship-bill-info'>
              {shippingDetails && <>
					
                <p>Email: {shippingDetails.email}</p>
                <p>First Name: {shippingDetails.firstname}</p>
                <p>Last Name: {shippingDetails.lastname}</p>
                <p>Primary Address: {shippingDetails.address}</p>
                <p>Secondary Address: {shippingDetails.address1}</p>
                <p>Country: {shippingDetails.country}</p>
                <p>State: {shippingDetails.state}</p>
                <p>City: {shippingDetails.city}</p>
                <p>Zipcode: {shippingDetails.zipcode}</p>
                <p>Phone: {shippingDetails.phone}</p>
                </>
              }
              </div>
            </div>
            <div className="order-details-page-box">
              <h3>Billing Information</h3>
              <div className='od-ship-bill-info '>
              {billingDetails && <>
					
              <p>Email: {billingDetails.email}</p>
                <p>First Name: {billingDetails.firstname}</p>
                <p>Last Name: {billingDetails.lastname}</p>
                <p>Primary Address: {billingDetails.address}</p>
                <p>Secondary Address: {billingDetails.address1}</p>
                <p>Country: {billingDetails.country}</p>
                <p>State: {billingDetails.state}</p>
                <p>City: {billingDetails.city}</p>
                <p>Zipcode: {billingDetails.zipcode}</p>
                <p>Phone: {billingDetails.phone}</p>
                </>
              }
              </div>
            </div>
          </div>

          {survey['count'] > 0 &&
          <div className="order-details-page-section m-top">
            <div className='od-table-panel'>
              <h2>Survey</h2>
              <div className='panelbody'>
                <table className="order-details-page-table">
                 
                  <tbody>
                 
                    <tr>
                      <td>{survey['conf_label']}</td>
                      <td>{survey['conf_name']}</td>
                      <td>{survey['conf_value']}</td>
                      
                    </tr>
                    <tr>
                      <td>{survey['course_label']}</td>
                      <td>{survey['course_name']}</td>
                      <td>{survey['course_value']}</td>
                      
                    </tr>
                    <tr>
                      <td>{survey['degree_label']}</td>
                      <td>{survey['degree_name']}</td>
                      <td>{survey['degree_value']}</td>
                      
                    </tr>
                  </tbody>

                </table>

              </div>
            </div>
          </div>
          }
         {confbadge.length > 0 &&
           <div className='od-table-panel m-top'>
                <h2>Conference Name Badge</h2>
                <div className='panelbody'>
                  <p className='conf-name-badge'>
                  {confbadge.map((item, index) => (
                    <><strong>First Name:</strong> {item.first_name_badge}<br />
                    <strong>Last Name:</strong> {item.last_name_badge} <br />
                    <strong>School or University:</strong> {item.school_name}</>
                  ))}
                  </p>
                  
                  <div className='conf-name-badge-hr'>&nbsp;</div>
                </div>
            </div>
          }
          <div className="order-details-page-section m-top">
            <div className='od-table-panel'>
              <h2>Shipment Details</h2>
              <div className='panelbody'>
                <table className="order-details-page-table">
                  <thead>
                    <tr className="order-details-page-odd">
                      <th width="80%">Title</th>
                      <th width="15%">Description</th>
                      <th>Quantity</th>
                      <th>Order Status</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="order-details-page-quick-links">
          <h3>Quick Links</h3>
          <ul>
            <li className='header-hello-text'><a>Manage My Profile</a></li>
            <li>
              <a href="/smww_pay">
              <input type="button" className='quick-links-reg-button' value="Register for a Course"/>
              </a>
            </li>

            </ul>
        </div>
      </div>

	 {isPrintModalOpen && (
        <div className="order-details-print-modal">
          <div className="order-details-print-modal-content" ref={printRef}>
          <div className='order-details-print-header'>
            <p style={{fontSize: '15.4px'}}>Print receipt</p>
            <a onClick={handleCloseModal} className="printpage-close-button">x</a>
          </div>
             <div className='order-det-print-button'>
              <ReactToPrint
                trigger={() => <button className="print-button">Print</button>}
                content={() => printRef.current}
              />
            </div>
            <div id="order-details-print">
              <OrderDetailsPrint order_id={order_id} uid={uid} />
            </div>
          </div>
        </div>
      )}
		{/* Send Receipt Modal */}
    {isSendReceiptModalOpen && (
        <div className="order-details-print-modal">
          <div className="order-details-print-modal-content">
            <div className='order-details-print-header'>
              <p style={{ fontSize: '15.4px' }}>Send Receipt</p>
              <a onClick={handleCloseModal} className="printpage-close-button">×</a>
            </div>
            <div className='order-det-print-button'>
              <button className="print-button" onClick={handleSendEmail}>Send email</button>
            </div>
            <div id="order-details-print" ref={sendemailRef}>
              <OrderDetailsSend order_id={order_id} uid={uid}/>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderDetailsPage;
