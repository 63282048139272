import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { configData } from "../config";
import './classes/Store.css';



const itemsPerPage = 20;

const Store = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOption, setSortOption] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [items, setItemList] = useState([]);
  const [category, setCategoryList] = useState([]);
  const app_url = configData.APP_URL;

  useEffect(() => {
   
    retrieveProducts();
    retrieveCategories();

 }, [])

  async function retrieveProducts() {
    const apparellistresponse = await fetch(
    app_url+'smwwcommerceapparel/getapparellist',
    {
      method: "GET",

      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const apparellistdata = await apparellistresponse.json();
  const apparellist = apparellistdata.data.result;
  setItemList(apparellist);
  
}

async function retrieveCategories() {
  const categorylistresponse = await fetch(
  app_url+'smwwcommerceapparel/getcategorylist',
  {
    method: "GET",

    headers: {
      'Content-Type': 'application/json',
    },
  }
)
const categorylistdata = await categorylistresponse.json();
const categorylist = categorylistdata.data.result;
setCategoryList(categorylist);

}
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategoryFilter(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const filteredItems = items
    .filter(item => categoryFilter === 'All' || item.category === categoryFilter)
    .filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));

  const sortedItems = [...filteredItems].sort((a, b) => {
    if (sortOption === 'Price: Low to High') {
      return a.price - b.price;
    } else if (sortOption === 'Price: High to Low') {
      return b.price - a.price;
    } else if (sortOption === 'Newest Arrivals') {
      return b.id - a.id;
    }
    return 0;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(sortedItems.length / itemsPerPage);

  return (
    <div className="store-container">
      <img className='apparel-banner' src={`${process.env.PUBLIC_URL}/images/apparel/pay_smww_apparels.png`} alt="athlete-bgd" />

      <div className="filters-container">
        <div className='sort-and-category'>
        <span>Sort by: </span>
        <select className='store-sort' value={sortOption} onChange={handleSortChange}>
          <option value="">Sort By</option>
          <option value="Price: Low to High">Price: Low to High</option>
          <option value="Price: High to Low">Price: High to Low</option>
          <option value="Newest Arrivals">Newest Arrivals</option>
        </select>
        <span>Category: </span>
        <select className='store-sort' value={categoryFilter} onChange={handleCategoryChange}>
          <option value="All">All</option>
          {category.map((list) => (
          
          <option value={list.id}>{list.category_name}</option>
        ))}
       
        </select>
        </div>
        <div className="search-container">
          <input
            type="text"
            className="store-search"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search"
          />
        </div>
      </div>

      {filteredItems.length === 0 ? (
        <p>
          There are no products in this category. To continue shopping, please{' '}
          <Link to="/store">click here</Link>.
        </p>
      ) : (
        <div className="items-container">
          {currentItems.map((item) => (
            <div className="item-card" key={item.id}>
              <Link to={`/store/${item.url_slug}`}>
                <div className='store-card-pf'>
                <img src={item.image} alt={item.name} />
                </div>
                <h2>{item.name}</h2>
                <p>${item.price}</p>
              </Link>
            </div>
          ))}
        </div>
      )}
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={currentPage === index + 1 ? 'active' : ''}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Store;
