import React, { useState, useEffect } from 'react';
import { configData } from "../config";
import './classes/ForgetPassword.css';

const ForgetPassword = () => {
  const [formData, setFormData] = useState({
    email: ''
  });
  const app_url = configData.APP_URL;

  const [errors, setErrors] = useState({});
  const[success,setSuccess] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(() => ({
      [name]: value
    }));
  };
  const validate = () => {
    let tempErrors = {};
    if (!formData.email) tempErrors.email = 'Email is required';
    
    setErrors(tempErrors);
   
    return Object.keys(tempErrors).length === 0;
  }; 
  const handleSubmit = async event => {
    event.preventDefault();
    if (validate()) {
      const postData = formData; 
        const response = await fetch(app_url+'forgotpassword/forgot', {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: {
          'Content-Type': 'application/json',
        },
        });
        const data = await response.json();
      
        const resultdata = data.data;
        if(resultdata.msg === "Success") {
          setSuccess(resultdata.msg_details);
        } 
    }
  };

  

  return (
    <div className="forget-page-container">
       {success.length > 0 && (
          <>
           {success.map((item, index) => {
             return <>
            <div className="set-message-done">
            <span className="cart-message-icon message-icon"><i className="fa fa-check-circle"></i></span> {item}
          </div></>
          
        })}  
          </>
        )}
      <form className="forget-page-form">
        <div className="forget-page-form-group">
          <label htmlFor="email" className="forget-page-label">
            Username or e-mail address <span className="forget-page-required">*</span>
          </label>
          <input
            type="text"
            name="email"
            required
            value={formData.email}
            onChange={handleChange}
            className="forget-page-input"
          />
          {errors.email && <span className="error">{errors.email}</span>}
        </div>
        <button type="submit" className="forget-page-submit-btn" onClick={handleSubmit}>E-mail new password</button>
      </form>
      <div className="forget-page-message forget-page-warning">
      <img src={`${process.env.PUBLIC_URL}/images/message-warning.png`} alt="warning" />
        <span>
         Please submit your email in above and you will be emailed a link which will log you in. Please check your spam folder if you do not see the email in a few minutes.
        </span>
      </div>
      <div className="forget-page-message forget-page-error">
      <img src={`${process.env.PUBLIC_URL}/images/message-error.png`} alt="error" />
        <p>
       If you do not receive your login email please navigate to the course you would like to register for and submit a new application. You will be able to complete your registration and pay at the end of the process, and at that time your new user account will be created. You may do this even if you have applied in the past.
        </p>
      </div>
    </div>
  );
};

export default ForgetPassword;
