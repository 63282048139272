import React, { useState, useEffect } from 'react';
import { configData } from "../config";
import './classes/ForgetPassword.css';
import { useNavigate, useSearchParams } from 'react-router-dom';

const VerifyPassword = () => {
  const[searchParams,setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [formData, setFormData] = useState({
    newpassword: '',
    token:token
  });
 
  const app_url = configData.APP_URL;

  const [errors, setErrors] = useState({});
  const[success,setSuccess] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
        ...prevState,
        [name]: value,
      }));
  };
  const validate = () => {
    let tempErrors = {};
    if (!formData.newpassword) tempErrors.newpassword = 'New Password is required';
    
    setErrors(tempErrors);
   
    return Object.keys(tempErrors).length === 0;
  }; 
  const handleSubmit = async event => {
    event.preventDefault();
    if (validate()) {
      const postData = formData; 
        const response = await fetch(app_url+'forgotpassword/verify', {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: {
          'Content-Type': 'application/json',
        },
        });
        const data = await response.json();
        
        const resultdata = data.data;
        if(resultdata.msg === "Success") {
            setSuccess(resultdata.msg_details);
        } 
    }
  };

  

  return (
    <div className="forget-page-container">
       {success.length > 0 && (
          <>
           {success.map((item, index) => {
             return <>
            <div className="set-message-done">
            <span className="cart-message-icon message-icon"><i className="fa fa-check-circle"></i></span> {item}
          </div></>
          
        })}  
          </>
        )}
      <form className="forget-page-form">
        <div className="forget-page-form-group">
          <label htmlFor="newpassword" className="forget-page-label">
          New Password <span className="forget-page-required">*</span>
          </label>
          <input
            type="password"
            name="newpassword"
            required
            value={formData.newpassword}
            onChange={handleChange}
            className="forget-page-input"
          />
          {errors.newpassword && <span className="error">{errors.newpassword}</span>}
        </div>
        <button type="submit" className="forget-page-submit-btn" onClick={handleSubmit}>Change password</button>
      </form>
    </div>
  );
};

export default VerifyPassword;
