import React from 'react'

const Terms = () => {
  return (
    <div className='terms-and-conditions-para'>
        <p>
        <b>SMWW Referral Program Terms and Conditions</b> <br />
        The SMWW Referral Program (the <b>"Program"</b>) is a referral partnership that enables current and former 
        SMWW students and conference attendees (<b>"Current Students"</b>) to earn credit toward SMWW Career Courses 
        and Conferences by recommending our courses and conferences to individuals who have never attended a 
        SMWW Career Conference or taken a SMWW Career Course (<b>"New Students"</b>). To participate, you must agree to these 
        Referral Program Terms and Conditions (the <b>"T&C"</b>).
        </p>

        <p>
        <b>Overview of the Program</b> <br />
        <b>1.1.</b> SMWW will provide you with a Referral Identification Number which you can use to promote the SMWW courses and conferences. <br />
        <b>1.2.</b> You can earn $100 credit (or a different amount if you have a separate agreement with SMWW) per New Student 
        that makes an Eligible Purchase. <br />
        <b>1.3.</b> For each Eligible Purchase, the Current Student who referred the New Student will be awarded a $100 
        credit (or a different amount if you have a separate agreement with SMWW) added to their SMWW wallet/account which the 
        Current Student may elect to apply against a current or future SMWW Career Course or SMWW Career Conference. 
        Subject to applicable law, credits are not transferable, expire 1 year from the date of issuance and cannot be redeemed 
        for cash or cash equivalent.            
        </p>

        <p>
        <b>2. Multiple Referrals</b> <br />
        A New Student may only apply one referral link. If a New Student references referral links from multiple 
        Current Students, only one referral code will be accepted. <br />
        <b>2.5.</b> Referral partners will receive credit on new SMWW customers only. 
        If the customer has purchased from SMWW in the past, no credit will be payable.
        </p>

        <p>
        <b>3. Eligible Purchases</b> <br />
        “Eligible Purchase” includes the registration and full payment by a New Student of an SMWW Career 
        Course or SMWW Career Conference. <b>Excluding the Toronto PrimeTime Career Conference, the Race Track Business 
        Conference, or the Concordia University Chicago Degree Programs.</b>
        </p>

        <p>
        <b>4.Miscellaneous</b> <br />
        <b>4.1.</b>  Subject to change by SMWW at any time. Void where restricted or prohibited by law. <br />
        <b>4.2.</b>  The T &C may be amended by SMWW, at its sole discretion, at any time and without prior notice to add to, remove, or otherwise change any of the terms and conditions, including, without limitation, by changing the amount of the referral credit, and discontinuing the Program entirely.
        </p>

    </div>
  )
}

export default Terms