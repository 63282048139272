import React from 'react'
import { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import './classes/UserTab.css';
import { configData } from "../config";
import EditProfile from './EditProfile';
import OrderPage from './OrderPage';
import ReferralPage from './ReferralPage';


function ViewTab  ()  {
 
  const [toggle, setToggle] = useState(1);

  const[userDetails,setUserDetails] = useState({});
  const[userOrderDetails,setUserOrderDetails] = useState({});

  const {uid} = useParams();
  const {tab} = useParams();
  const loggedin_uid = localStorage.getItem('uid');
  const navigate = useNavigate();

  if(uid != loggedin_uid) {
    alert("You are not authorized to access");
    navigate("/");
  }
  const app_url = configData.APP_URL;

  const domain_url = configData.DOMAIN_URL;
 
  
  function updateToggle(id){
    setToggle(id)
    if(id == 2) navigate("/user/"+uid+"/edit");
    if(id == 3) navigate("/user/"+uid+"/myorders");
    if(id == 4) navigate("/user/"+uid+"/myreferral");

  }
  useEffect(() => {
   
    retrieveUserDetails();
    retrieveUserOrderDetails();
    if(tab == "myorders") {
      setToggle(3);

    }
    if(tab == "edit") {
      setToggle(2);

    }
    if(tab == "myreferral") {
      setToggle(4);

    }
  },[]);

  async function retrieveUserDetails() {
    if(uid) {
    const response = await fetch(
      app_url+'myorder/getuserdetails?uid='+uid,
      {
        method: "GET",
  
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const responsedata = await response.json();
    if(responsedata.data.result === "Success") {
      if(responsedata.data.date_of_app) responsedata.data.user.date_of_app = responsedata.data.date_of_app;
      setUserDetails(responsedata.data.user);
    }
  }
  }
  async function retrieveUserOrderDetails() {
    if(uid) {
    const response = await fetch(
      app_url+'myorder/getuserorderdetails?uid='+uid,
      {
        method: "GET",
  
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const responsedata = await response.json();
    if(responsedata.data) {
    setUserOrderDetails(responsedata.data);
    } 
  }
  }
  return (
   <>
   
    <div id="main">
 
      <div class="left"></div>

      <div class="center left-center">
    
      <h2 class="element-invisible">Primary tabs</h2>
      <ul class="tabs primarya">
        <li className= {toggle === 1? "active" : ""} onClick={()=> updateToggle(1)}><a>View<span class="element-invisible">(active tab)</span></a></li>
        <li className= {toggle === 2? "active" : ""} onClick={()=> updateToggle(2)}><a>Edit Profile</a></li>
        <li className= {toggle === 3? "active" : ""} onClick={()=> updateToggle(3)}><a>My Orders</a></li>
        <li className= {toggle === 4? "active" : ""} class="four" onClick={()=> updateToggle(4)}><a>My Referral Wallet</a></li>
      </ul>    
    
      <div  className= {toggle === 1? "show-content" : "content"}>
        <div class="profile" typeof="sioc:UserAccount" about="/users/rathnamtestpreferatinet">
          <div class="user-image" style={{ marginRight: '2%' }}><img  src={domain_url+"pictures/notavailable.jpg"} width="100px" height="100px" alt = "user"/></div>
          <div class="user-name-loc">
            <span class="user-name" style={{marginBottom:"5px"}}>{userDetails.first_name} {userDetails.last_name}</span>
            <span class="user-loc" style={{marginBottom:"5px"}}>{userDetails.city}, {userDetails.state}, {userDetails.country}</span>
            <span style={{ backgroundColor: '#FFFF00', padding: '8px', color: '#000' }}><strong>Recent app: {userDetails.date_of_app}</strong></span>
          </div>
          <div class="user_total_amount" style={{ float: 'right', fontWeight: 'bold', fontSize: '16px' }}>Total Purchased: $0.00</div> 
        </div>
      </div>
      <div  className= {toggle === 2? "show-content" : "content"}>
        <EditProfile uid={uid} />
      </div>
      <div  className= {toggle === 3? "show-content" : "content"}>
        <OrderPage data={userOrderDetails}/>
      </div>
      <div  className= {toggle === 4? "show-content" : "content"}>
        <ReferralPage uid={uid}/>
      </div>
    </div>

    


<div class="right">

<div className="order-details-page-quick-links">
          <h3>Quick Links</h3>
          <ul>
            <li className='header-hello-text'><a onClick={()=> updateToggle(2)}>Manage My Profile</a></li>
            <li>
              <a href="/smww_pay">
              <input type="button" className='quick-links-reg-button' value="Register for a Course"/>
              </a>
            </li>

            </ul>
        </div>

</div>
  <div class="clear"></div> 
  
</div>
    </>
    
    
  )
}

export default ViewTab