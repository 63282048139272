import React, { useState, useEffect, useRef} from 'react';



const LocalStorage = () => {

    const iframeRef = useRef(null);
    const iframe = iframeRef.current;
    const [isIFrameLoaded, setIsIFrameLoaded] = useState(false);
    

    useEffect(() => {
        if(iframeRef.current != null) setIsIFrameLoaded(true);
        onMyFrameLoad();
    },[isIFrameLoaded]) 
   
    function onMyFrameLoad() {
        var order_id = localStorage.getItem('order_id'); 
           alert(order_id);
        var data = {order_id: order_id};
        var iframeEl = document.getElementById("myIframe"); 
        iframeEl.contentWindow.postMessage(JSON.stringify(data), 'http://newsmww.preferati.net');
      };
      return (
        <iframe id="myIframe" ref={iframeRef} src="http://newsmww.preferati.net/api/get_order_id" ></iframe>
      );
};
export default LocalStorage;