import React from 'react';
import { useState, useEffect, useRef} from 'react';
import { configData } from "../config";
import './classes/OrderDetailsSend.css';

const OrderDetailsSend = ({order_id,uid}) => {
  const app_url = configData.APP_URL;
  
  const[billing,setBillingDetails] = useState({});
  const[orderProducts,setOrderProducts] = useState([]);
  const[discounts,setDiscounts] = useState([]);
  const[order_details,setOrder] = useState([]);

  useEffect(() => {
   
    retrievePrintOrderDetails();
    
  },[]);
  async function retrievePrintOrderDetails() {
    
    const response = await fetch(
      app_url+'smwwreceipt/getuserreceiptdetails?uid='+uid+"&order_id="+order_id,
      {
        method: "GET",
  
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const responsedata = await response.json();
    if(responsedata.data.msg === "Success") {
    
      setBillingDetails(responsedata.data.result['billing']);
      setOrderProducts(responsedata.data.result['product']);
      setDiscounts(responsedata.data.result['discounts']);
      setOrder(responsedata.data.result['order']);
    } 

  }

  
  const domain_url = configData.DOMAIN_URL;
  return (
    <div className="order-details-page-print">
      <div className="order-details-page-print-header">
        <img src={domain_url+"/images/receipt_head_smww.png"} alt="Header" />
      </div>
      <div className="order-details-page-print-content">
        <div className='product-order-invoice-details'>
          <div style={{fontSize: '18px'}}>
        <p><strong>Invoice To:</strong> <br />
        {billing.firstname} {billing.lastname},<br />
        {billing.address},<br />
        {billing.city}{", "+billing.state}{", "+billing.country}<br />
        {billing.zipcode},<br />
        {billing.email}</p>
        </div>

        <div style={{fontSize: '18px'}}>
        <p><strong>Invoice Number:</strong> {order_details.orderid}</p>
        <p><strong>Date:</strong> {order_details.today_date}</p>
        <p><strong>Amount Paid:</strong> {order_details.order_total}</p>
        <p><strong>Order Id:</strong> {order_details.orderid}</p>
        </div>
        </div>


<div className='line-for-print-page'>&nbsp;</div>
        <table className="order-details-page-print-table">
          <thead>
            <tr>
              <th width="65%">Product Name</th>
              <th>Unit Price</th>
              <th style={{textAlign: 'right'}}>Price (Discount Applied)</th>
            </tr>
          </thead>
          <tbody>
          {orderProducts.map((item, index) => (
             <tr className="order-details-page-even">
             <td>{item.product_title}</td>
             <td>${item.unit_amount}</td>
           
             <td style={{textAlign: 'right'}}>${item.calculated_amount}</td>
             
           
           </tr>
             ))}
            
          </tbody>
        </table>
        <div className='print-modal-cty-shp'>
          <table className='order-details-disc-shipp'>
            <tbody>
            {discounts &&<>
                    {discounts.map((item, index) => (
                      <tr>
                       <td><strong>{item.name}</strong></td>
                        
                        <td>{item.amount}</td>
                      </tr>
                     ))}
                     </> 
                    }
             
            </tbody>
          </table>

        </div>

        <div className='line-for-print-page'>&nbsp;</div>        

        <div className='print-page-payment-info'>
          <div>
            <h2 className='print-modal-total-paymt-info'>Payment Information</h2>
              <p>{order_details.paid_by}<br />
              {order_details.paid_on}</p>
            </div>
            <div>
            <h2 className='print-modal-total-print'>&nbsp;&nbsp;&nbsp;&nbsp;Total &nbsp;&nbsp;&nbsp;&nbsp; {order_details.order_total}</h2>
            </div>    
                
        </div>

        <div className='print-modal-thanks'>
        <h2>Thank you for your business!</h2>

          <p>President & Founder <br />
          <strong>Dr. Lynn Lashbrook</strong></p>
        </div>
        

        <div className="order-details-page-print-footer">
          <img src={domain_url+"/images/receipt_bottom.png"} alt="Footer" />
          
          <div className='print-page-footer-content'>
          <div>
        <p>Sports Management Worldwide <br />
        1100 NW Glisan 2B<br />
        Portland, Oregon, USA 97209</p>
        </div>

        <div>
        <p>US & Canada toll free: 888-95-AGENT<br />
        UK: +44 (0)871 288 4799<br />
        International: 011-503-445-7105<br />
        info@smww.com</p>
        </div>
        </div>
      <p className='order-print-copyright'>Sports Management Worldwide Copyright © 2024 | All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsSend;
