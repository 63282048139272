import React, { useState, useRef, useEffect } from 'react';
import './classes/Footer.css';
import ReCAPTCHA from "react-google-recaptcha";
import { configData } from "../config";

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [statelist, setStateList] = useState({});
  const [countrieslist, setCountriesList] = useState({});
  const[error,setError] = useState({});
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    country: 'United States',
    city: '',
    state: '',
    pageURL: window.location.href, 
    emailLists: [],
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const app_url = configData.APP_URL;
  useEffect(() => {
    
    retrieveCountriesList();
    retrieveStateList();
  },[]);

  async function retrieveStateList() {
    const statelistresponse = await fetch(app_url + 'smwwstates/getstatelist', {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const statelistdata = await statelistresponse.json();
    const statelist = {};
    Object.keys(statelistdata.data.result).forEach(function(key) {
      statelist[key] = statelistdata.data.result[key];
    });
    setStateList(statelist);
   
  }

  async function retrieveCountriesList() {
    const countrieslistresponse = await fetch(app_url + 'smwwcountries/getcountrieslist', {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const countrieslistdata = await countrieslistresponse.json();
    const countrieslist = {};
    Object.keys(countrieslistdata.data.result).forEach(function(key) {
      countrieslist[key] = countrieslistdata.data.result[key];
    });
    setCountriesList(countrieslist);
    
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsFormSubmitted(false); 
  };

  const validateForm = (data) => {
    const errors = [];
    
    if (!formData.email.trim()) {
      
      errors.push("E-mail address field is required.");
    }
   
  
    return errors;
  }

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData((prevData) => {
        if (checked) {
          return { ...prevData, emailLists: [...prevData.emailLists, value] };
        } else {
          return {
            ...prevData,
            emailLists: prevData.emailLists.filter((list) => list !== value),
          };
        }
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleFormSubmit = async e => {
    e.preventDefault();
    const newErrors = validateForm(formData);
    setError(newErrors);
    
    if (Object.keys(newErrors).length === 0) {
      const postData = formData; 
   
      const response = await fetch(app_url+'newsletter/putuserdetails', {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: {
        'Content-Type': 'application/json',
      },
      });
      const data = await response.json();
      if(data.data.result === "user_created") {
        setIsFormSubmitted(true);
      }
      
    }
    
  };

  const recaptchaRef = useRef(null);
  const sitekey = configData.GOOGLE_RECAPTCHA_SITE_KEY;

  

  return (
    <>
      <div id="footer">
        <div className="footer">
          <div className="float_left l">
            <a href="/contact">Contact Us</a> | <a href="/directors">Our Staff</a> |{" "}
            <a href="/privacy">Privacy</a>
            <br />
            US & Canada toll free: <a href="tel:888-95-24368">888-95-AGENT</a>
            <br />
            UK <a href="tel:+44 (0) 8712 884799">+44 (0)871 288 4799</a> |
            International: <a href="tel:011-503-445-7105">011-503-445-7105</a>
          </div>
          <div className="float_left m">
            <div align="center" className="newsletter_btn_wrapper">
              <a
                className="newsletter_btn"
                onClick={openModal}
              >
                JOIN OUR NEWSLETTER
              </a>
            </div>
          </div>
          <div style={{ marginTop: "-23px" }} />
          <div className="float_right r">
            Sports Management Worldwide <br />
            Copyright © 2024 | All Rights Reserved.
          </div>
          <div style={{ clear: "both" }}>&nbsp;</div>
          <div>&nbsp;</div>
        </div>
      </div>
      <footer id="sticky">
        <div className='sticky-apply-now-button'>
          <a href="/application">
            Apply Now Free
          </a>
        </div>
      </footer>

      {isModalOpen && (
        <div className="footer-newsletter-modal-overlay">
          <div className="footer-newsletter-modal-content">
            <span className="footer-newsletter-close" onClick={closeModal}>
              &times;
            </span>
            <h2>SMWW Newsletter</h2>
            <p>Thank you for joining the Sports Management Worldwide newsletter! You're joining the thousands of sports industry enthusiasts looking to gain or further their sports knowledge.</p>
            <p>In order for us to better understand our audience so we can craft the best experience possible, please take a minute to tell us a little bit more about yourself.</p>
            <p>Please feel free to contact us should you have any questions or ideas.</p>
            <p>Thank you,<br />
            Sports Management Worldwide<br />
            503-445-7105<br />
            info@SMWW.com</p>

            {!isFormSubmitted ? (
              <form onSubmit={handleFormSubmit} className='footer-newsletter-form'>
                <label>Email<span style={{color: "red"}}>&nbsp;*</span></label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
                <label>First Name<span style={{color: "red"}}>&nbsp;*</span></label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                />
                <label>Last Name<span style={{color: "red"}}>&nbsp;*</span></label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                />
                <label>Country<span style={{color: "red"}}>&nbsp;*</span></label>
                <select name="country" value={formData.field_country} onChange={handleInputChange}>
                <option value="">Select Country</option>
                {Object.keys(countrieslist).map(item => {
                  return <option key={countrieslist[item].label} value={countrieslist[item].label}>{countrieslist[item].label}</option>
                })}
                </select>
                <label>City</label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                />
                <label>State/Province<span style={{color: "red"}}>&nbsp;*</span></label>
                  <select name="state" value={formData.field_state} onChange={handleInputChange}>
                    <option value=" ">Select a State or Province</option>
                    <option value="-">Outside the US or Canada</option>
                    {Object.keys(statelist).map(state => {
                      return <option key={statelist[state].name} value={statelist[state].name}>{statelist[state].name}</option>;
                    })}
           
                  </select>
                <label>Email Lists<span style={{color: "red"}}>&nbsp;*</span></label>

        <div className="footer-newsletter-checkbox-group">
          <div className="footer-newsletter-checkbox-item">
            <input
              type="checkbox"
              id="Baseball"
              name="emailLists"
              value="Baseball"
              checked={formData.emailLists.includes('Baseball')}
              onChange={handleInputChange}
            />
            <label htmlFor="Baseball">Baseball</label>
          </div>

          <div className="footer-newsletter-checkbox-item">
            <input
              type="checkbox"
              id="Basketball"
              name="emailLists"
              value="Basketball"
              checked={formData.emailLists.includes('Basketball')}
              onChange={handleInputChange}
            />
            <label htmlFor="Basketball">Basketball</label>
          </div>

          <div className="footer-newsletter-checkbox-item">
            <input
              type="checkbox"
              id="Conferences-and-Events"
              name="emailLists"
              value="Conferences and Events"
              checked={formData.emailLists.includes('Conferences and Events')}
              onChange={handleInputChange}
            />
            <label htmlFor="Conferences-and-Events">Conferences and Events</label>
          </div>

          <div className="footer-newsletter-checkbox-item">
            <input
              type="checkbox"
              id="Cricket"
              name="emailLists"
              value="Cricket"
              checked={formData.emailLists.includes('Cricket')}
              onChange={handleInputChange}
            />
            <label htmlFor="Cricket">Cricket</label>
          </div>

          <div className="footer-newsletter-checkbox-item">
            <input
              type="checkbox"
              id="eSports"
              name="emailLists"
              value="eSports"
              checked={formData.emailLists.includes('eSports')}
              onChange={handleInputChange}
            />
            <label htmlFor="eSports">eSports</label>
          </div>

          <div className="footer-newsletter-checkbox-item">
            <input
              type="checkbox"
              id="Football"
              name="emailLists"
              value="Football"
              checked={formData.emailLists.includes('Football')}
              onChange={handleInputChange}
            />
            <label htmlFor="Football">Football</label>
          </div>

          <div className="footer-newsletter-checkbox-item">
            <input
              type="checkbox"
              id="Hockey"
              name="emailLists"
              value="Hockey"
              checked={formData.emailLists.includes('Hockey')}
              onChange={handleInputChange}
            />
            <label htmlFor="Hockey">Hockey</label>
          </div>

          <div className="footer-newsletter-checkbox-item">
            <input
              type="checkbox"
              id="Motorsports"
              name="emailLists"
              value="Motorsports"
              checked={formData.emailLists.includes('Motorsports')}
              onChange={handleInputChange}
            />
            <label htmlFor="Motorsports">Motorsports</label>
          </div>

          <div className="footer-newsletter-checkbox-item">
            <input
              type="checkbox"
              id="Rugby"
              name="emailLists"
              value="Rugby"
              checked={formData.emailLists.includes('Rugby')}
              onChange={handleInputChange}
            />
            <label htmlFor="Rugby">Rugby</label>
          </div>

          <div className="footer-newsletter-checkbox-item">
            <input
              type="checkbox"
              id="Soccer"
              name="emailLists"
              value="Soccer"
              checked={formData.emailLists.includes('Soccer')}
              onChange={handleInputChange}
            />
            <label htmlFor="Soccer">Soccer</label>
          </div>

          <div className="footer-newsletter-checkbox-item">
            <input
              type="checkbox"
              id="Sports-Management-Graduate-Programs"
              name="emailLists"
              value="Sports Management Graduate Programs (MBA, Ph.D, Ed.D, MASL)"
              checked={formData.emailLists.includes('Sports Management Graduate Programs (MBA, Ph.D, Ed.D, MASL)')}
              onChange={handleInputChange}
            />
            <label htmlFor="Sports-Management-Graduate-Programs">Sports Management Graduate Programs (MBA, Ph.D, Ed.D, MASL)</label>
          </div>
        </div>

<span className='newsletter-footer-desc'>By submitting this form, you are consenting to receive marketing emails 
  from: Sports Management Worldwide, 1100 NW Glisan Suite 2B, www.smww.com, Portland OR, OR, 97209, US, http://www.sportsmanagementworldwide.com. You can revoke your consent to receive emails at any time by using 
  the SafeUnsubscribe® link, found at the bottom of every email. 
  <a>Emails are serviced by Constant Contact</a></span>
  <br />

  <div className="footer-recaptcha">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={sitekey}
              size="visible"
              required
            />
            </div>

        <button type="submit" className="footer-newsletter-submit">Sign Up</button>
              </form>
            ) : (
              <div className="footer-newsletter-thankyou">
                Thank you for your interest!<br />
                Someone from SMWW will be contacting you soon.
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
