import React, { useState, useEffect} from 'react';
import './classes/ThankyouPage.css';
import { Link } from 'react-router-dom';
import { configData } from "../config";
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';

const ThankyouPage = () => {
  
 
  const [productlist, setProductList] = useState([]);
  const [conferenceAnswer, setConferenceAnswer] = useState('');
  const [courseAnswer, setCourseAnswer] = useState('');
  const [partnershipAnswer, setPartnershipAnswer] = useState('');
  const data  = useParams();	
  const order_id = data.order_id;
  
  const app_url = configData.APP_URL;
  const [producttypes, setProductTypes] = useState();
  const [formData, setFormData] = useState({
    enrolledcourses:[],
    course:'',
    conference:'',
    enrolledconference:'',
    enrolleddegree:'',
    partnership:'',
    order_id:order_id
  })
  const navigate = useNavigate();
  useEffect(() => {
    retrieveProducts();
    retrieveOrderProductTypes(order_id);
  }, [])
  async function retrieveProducts() {
    const productlistresponse = await fetch(
      app_url+'smwwcommercethankyou/getproductlist',
      {
        method: "GET",
  
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const productlistdata = await productlistresponse.json();
    const productlist = productlistdata.data.result;
    setProductList(productlist);
    
  }
  async function retrieveOrderProductTypes(order_id) {
  
    const orderProductTypes = await fetch(
      app_url+'smwwcommercecheckout/getorderproducttypes?order_id='+order_id,
      {
        method: "GET",
  
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const orderProductTypesdata = await orderProductTypes.json();
    setProductTypes(orderProductTypesdata.data.result);
    
    
  }
  
  const handleChange = event => {
    const { name, value} = event.target;
   
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  
    if(name === "conference") {
      setConferenceAnswer(value);
    }
    if(name === "course") {
      setCourseAnswer(value);
    }
    if(name === "partnership") {
      setPartnershipAnswer(value);
    }
    if(name === 'enrolledcourses') {
      
      const valuee = Array.from(event.target.selectedOptions, option => option.value);
      setFormData(prevState => ({
        ...prevState,
        enrolledcourses: valuee,
      }));
     
      
    }
  };
  const validateForm = () => {
   console.log(formData);
    if(formData.conference === "") {
      alert("Please Select Have you been to an SMWW Conference before?");
      return false;
    }
    if(formData.conference === "yes") {
      if(formData.enrolledconference == "") {
        alert("Please Select To how many?");
        return false;
      }
    }
    if(formData.course === "") {
      alert("Please Select Have you taken an SMWW Course before?");
      return false;
    }
    if(formData.course === "yes") {
      if(formData.enrolledcourses.length === 0) {
        alert("Please Select a Course");
        return false;
      }
    }
    if(formData.partnership === "") {
      alert("Please Select Have you enrolled in SMWW/Concordia University Chicago Partnership?");
      return false;
    }
    if(formData.partnership === "yes") {
      if(formData.enrolleddegree == "") {
        alert("Please Select Have you enrolled in SMWW/Concordia University Chicago Partnership?");
        return false;
      }
    }
    return true;
    
  }
  const handleSubmit = async event => {
    event.preventDefault();
    
    if (validateForm()) {
      const postData = formData; 
      const response = await fetch(app_url+'smwwcommercethankyou/putsurveyinfo', {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: {
        'Content-Type': 'application/json',
      },
      });
      const data = await response.json();
      if(data.data.result === "Success") {
        navigate("/");
      }

  }
}
  return (
    <div className='thankyou-container'>
	<div className="thankyou-text">Thank You For Your Purchase</div>															  
      <Helmet><title>SMWW Order Thank You Page</title></Helmet>
      {producttypes && (
       producttypes.includes("7") ? (
      <div className='thank-container'>
        
          <img className='thankyou-image' src={`${process.env.PUBLIC_URL}/images/smww-thankyou-transparent.png`} alt="thank-you" />
			<form className="thankyou-form">
          <div className="form-group">
            <h2>Have you been to an SMWW Conference before?</h2>
            <div className="yes-no-buttons">
              <input
                type="radio"
                id="conference-yes"
                name="conference"
                value="yes"
                checked={conferenceAnswer === 'yes'}
                onChange={handleChange}
              />
              <label htmlFor="conference-yes" className={conferenceAnswer === 'yes' ? 'active' : ''}>
                Yes
              </label>
              <input
                type="radio"
                id="conference-no"
                name="conference"
                value="no"
                checked={conferenceAnswer === 'no'}
                onChange={handleChange}
              />
              <label htmlFor="conference-no" className={conferenceAnswer === 'no' ? 'active' : ''}>
                No
              </label>
            </div>
             {conferenceAnswer === 'yes' && (
              <div className='th-dropdown-1'>
               <span className='th-dropdown-1-label'>To How many?</span>
              <select className="th-dropdown-1" name="enrolledconference" onChange={handleChange}>
                <option value="">Select</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
                <option value="4">Four</option>
                <option value="5">Five</option>
                <option value="6">Six</option>
                <option value="7">Seven</option>
                <option value="8">Eight</option>
                <option value="9">Nine</option>
                <option value="10">Ten</option>
              </select>
              </div>
            )}
            {conferenceAnswer === 'no' && (
              <div className="info-link">
                Here you can find more information about our Conferences: <a href="https://www.sportsmanagementworldwide.com/conferences" target="_blank" rel="noopener noreferrer">https://www.sportsmanagementworldwide.com/conferences</a>
              </div>
            )}
          </div>

          <div className="form-group">
            <h2>Have you taken an SMWW Course before?</h2>
            <div className="yes-no-buttons">
             <input
                type="radio"
                id="course-yes"
                name="course"
                value="yes"
                checked={courseAnswer === 'yes'}
                onChange={handleChange}
                
              />
              <label htmlFor="course-yes" className={courseAnswer === 'yes' ? 'active' : ''}>
                Yes
              </label>
              <input
                type="radio"
                id="course-no"
                name="course"
                value="no"
                checked={courseAnswer === 'no'}
                onChange={handleChange}
              />
              <label htmlFor="course-no" className={courseAnswer === 'no' ? 'active' : ''}>
                No
              </label>
            </div>
            {courseAnswer === 'yes' && (
              <select multiple className="th-dropdown-2" name="enrolledcourses" onChange={handleChange}>
                {productlist.map((item, index) => (
                <option value={item.sku}>{item.name}</option>
                ))}
              </select>
            )}
            {courseAnswer === 'no' && (
              <div className="info-link">
                Here you can find more information about our Courses: <a href="https://www.sportsmanagementworldwide.com/courses" target="_blank" rel="noopener noreferrer">https://www.sportsmanagementworldwide.com/courses</a>
              </div>
            )}
          </div>

          <div className="form-group">
            <h2>Have you enrolled in SMWW/Concordia University Chicago Partnership?</h2>
            <div className="yes-no-buttons">
              <input
                type="radio"
                id="partnership-yes"
                name="partnership"
                value="yes"
                checked={partnershipAnswer === 'yes'}
                onChange={handleChange}
                
              />
              <label htmlFor="partnership-yes" className={partnershipAnswer === 'yes' ? 'active' : ''}>
                Yes
              </label>
              <input
                type="radio"
                id="partnership-no"
                name="partnership"
                value="no"
                checked={partnershipAnswer === 'no'}
                onChange={handleChange}
              />
              <label htmlFor="partnership-no" className={partnershipAnswer === 'no' ? 'active' : ''}>
                No
              </label>
            </div>
            {partnershipAnswer === 'yes' && (
              <select className="th-dropdown-3" name="enrolleddegree" onChange={handleChange}>
                <option value="">Select</option>
                <option value="PhD Sports Leadership">PhD Sports Leadership</option>
                <option value="EdD Sports Leadership">EdD Sports Leadership</option>
                <option value="Master of Arts in Sports Leadership">Master of Arts in Sports Leadership</option>
                <option value="MBA in Sports Leadership">MBA in Sports Leadership</option>
              </select>
            )}
            {partnershipAnswer === 'no' && (
              <div className="info-link">
                Here you can find more information: <a href="https://www.sportsmanagementworldwide.com/content/sports-management-degrees" target="_blank" rel="noopener noreferrer">https://www.sportsmanagementworldwide.com/content/sports-management-degrees</a>
              </div>
            )}
          </div>

          <button type="submit" className="submit-button" onClick={handleSubmit}>Submit</button>
        </form>
      
      </div>
       ) : (
        <div className='course-thank-container'>
        
        <img className='thankyou-image course-thankpage-image' src={`${process.env.PUBLIC_URL}/images/smww-thankyou-transparent.png`} alt="thank-you" />
      <div className="thank-action_buttons">
          <Link to="/smww_pay">
            <button className="thankyou-buttons">Go to Courses</button>
          </Link>
          <Link to="/">
            <button className="thankyou-buttons">Go to Homepage</button>
          </Link>
      </div>
      </div>
      ))}
    </div>
	
  );
}

export default ThankyouPage;
