import React, { useState, useRef, useEffect } from 'react';
import './classes/AdvancedCertificate.css';
import { configData } from "../config";
import ReCAPTCHA from "react-google-recaptcha";
import MinicartLength from './MinicartLength';
import ReactDOM from "react-dom/client";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const AdvancedCertificate = () => {
    const [isImageFullScreen, setIsImageFullScreen] = useState(false);
    
    const [type, setOpenModal] = useState(null);
    const [statelist, setStateList] = useState({});
    const [countrieslist, setCountriesList] = useState({});
    const [agentproducts, setAgentProducts] = useState({});
    const [allproducts, setAllProducts] = useState({});
    const [paymentplanlist, setPaymentPlanList] = useState([]);
    const [packageid, setPacakgeId] = useState(null);
    const [agentcourse, setAgentCourse] = useState(null);
    const [electivecourse, setElectiveCourse] = useState(null);
    const [paymentplan, setPaymentPlan] = useState(null);
    const [message, setSuccessMessage] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [buttontext, setButtonText] = useState("SUBMIT");
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        primary_phone: '',
        city: '',
        country: '',
        state: '',
        province: '',
        question: '',
        pageURL: window.location.href, 
        showStateField: true,
        showProvinceField: false,
        showForm: true,
        showSuccessMessage: false,
    });

    const app_url = configData.APP_URL;
    const domain_url = configData.DOMAIN_URL;
    const recaptchaRef = useRef(null);

    useEffect(() => {
        retrieveAgentProducts();
        retrieveAllProducts();
        retrieveStateList();
        retrieveCountriesList();
    }, [])

    async function retrieveAgentProducts() {
        const agentproductlistresponse = await fetch(
          app_url+'smwwcommercepackage/getagentproducts',
          {
            method: "GET",
      
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        const agentproductlistdata = await agentproductlistresponse.json();
        const agentproductlist = agentproductlistdata.data.result;
        setAgentProducts(agentproductlist);
        
    }
    async function retrieveAllProducts() {
        const productlistresponse = await fetch(
          app_url+'smwwcommercepackage/getproductlist',
          {
            method: "GET",
      
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        const productlistdata = await productlistresponse.json();
        const productlist = productlistdata.data.result;
        setAllProducts(productlist);
        
    }
    async function retrievePaymentPlanList(postData) {
  
        const paymentplanlistresponse = await fetch(
        app_url+'smwwcommercepackage/getpaymentplanlist',
        {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
            'Content-Type': 'application/json',
            },
        });
        const paymentplanlistdata = await paymentplanlistresponse.json();
        const payplanlist = paymentplanlistdata.data.data;
        setPaymentPlanList(payplanlist);
       
    }
    async function retrieveStateList() {
        const statelistresponse = await fetch(
          app_url+'smwwstates/getstatelist',
          {
            method: "GET",
    
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        const statelistdata = await statelistresponse.json();
        
        const statelist = {};
        Object.keys(statelistdata.data.result).forEach(function(key) {
          
          statelist[key] = statelistdata.data.result[key];
        
        });
        setStateList(statelist);
       
    }
    async function retrieveCountriesList() {
        const countrieslistresponse = await fetch(
          app_url+'smwwcountries/getcountrieslist',
          {
            method: "GET",
    
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        const countrieslistdata = await countrieslistresponse.json();
    
        setCountriesList(countrieslistdata.data.result);
        console.log(countrieslistdata.data.result);
        
    }
    const handleChange = event => {
        const { name, value} = event.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value,
        }));
      
        if(event.target.name === 'country') {
            
          if(event.target.value === 'US') {
            setFormData(prevState => ({
              ...prevState,
              showProvinceField: false,
              showStateField: true,
            }));
    
          } else {
            setFormData(prevState => ({
              ...prevState,
              showProvinceField: true,
              showStateField: false,
            }));
           
          }
        }
    }
    
    const handleImageClick = () => {
        setIsImageFullScreen(true);
    };

    const handleCloseClick = () => {
        setIsImageFullScreen(false);
    };

   
    const sitekey = configData.GOOGLE_RECAPTCHA_SITE_KEY;

    const handleModalOpen = (type,id) => {
        setPacakgeId(id);
        setElectiveCourse(null);
        setAgentCourse(null);
        if(type === "payment_plan") {
            const postData = {
                id: id
            }
            retrievePaymentPlanList(postData);
        }
        setOpenModal(type);
        
    };

    const handleModalClose = () => {
        setOpenModal(null);
    };
   
    const handleAgentCourseSubmit = async event => {
        event.preventDefault();

        if(agentcourse === null) {
            alert("Pick any Agent Course");
            return false;
        } else {
            
            setOpenModal("elective_course");
        }
        
    }
   
    const handleElectiveCourseSubmit = async event => {
        event.preventDefault();
        if(electivecourse === null) {
            alert("Pick any Elective Course");
            return false;
        } else {
            const postData = {
                id: packageid,
                agent_course: agentcourse,
                elective_course: electivecourse,
            }
            retrievePaymentPlanList(postData);
            setOpenModal("payment_plan");
        }
    }
    
    const handleSubmit = async event => {
        event.preventDefault();
        const postData = {
            id: packageid,
            agent_course: agentcourse,
            elective_course: electivecourse,
            payment_plan: paymentplan,
            order_id: localStorage.getItem('order_id'),
            country: '',
        }
        const response = await fetch(app_url+'smwwcommercepackage/addtocart', {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        const yii_session = data.data.session;
        if(data.data.result === "order_created") {
            if(yii_session.order_id) localStorage.setItem('order_id', yii_session.order_id);
            else localStorage.setItem('order_id', data.data.order_id);
            setOpenModal('success');
            setSuccessMessage(data.data.messages.success);
            
            const root = ReactDOM.createRoot(document.getElementById("top-header-cart-icon"));
            root.render(<MinicartLength length={data.data.products.length} />);
        }
    }
    const validateForm = () => {
        console.log(formData);
        if(formData.first_name === "") {
           alert("First Name is required");
           return false;
        }
        if(formData.last_name === "") {
            alert("Last Name is required");
            return false;
        }
        if (!formData.email.trim()) {
            alert("Email is required");
            return false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            alert("Email is invalid");
            return false;
        }
        if (!formData.primary_phone.trim()) {
            alert("Phone Number is required");
            return false;
            
        }
        if (!formData.country.trim()) {
            alert("Country is required");
            return false;
            
        }
        const recaptchaValue = recaptchaRef.current.getValue();
          if (!recaptchaValue) {
            alert("Please verify the reCAPTCHA!");
            return false;
          }
         return true;
         
    }
    const handleFormSubmit = async event => {
        event.preventDefault();
    
        if (validateForm()) {
          setDisabled(true);
          setButtonText("Saving please wait...");
          const postData = formData; 
          const response = await fetch(app_url+'smwwcommercepackage/application', {
          method: 'POST',
          body: JSON.stringify(postData),
          headers: {
            'Content-Type': 'application/json',
          },
          });
          const data = await response.json();
          if(data.data.result === "user_created") {
            setFormData(prevState => ({
                ...prevState,
                showForm: false,
                showSuccessMessage: true,
              }));
            
          }
        }
    };
    const renderModal = (type,id) => {
        if (type === "agent") {
            return (
                <div className="ad-modal-overlay">
                    <div className="ad-modal">
                        <button className="adm-close-button" onClick={handleModalClose}>×</button>
                        <img 
                            src={domain_url+'/images/certs/'+id+'.png'} 
                            alt={`${type} badge`} 
                            className="modal-badge" 
                        />
                        <h2>Pick an Agent Course</h2>
                        
                        <form className='ad-modal-form'>
                        <div class="modal-body">
                            {agentproducts.map((item, index) => (
                                <>
                                    <label>
                                    <input type="radio" id="f-option" name="only_agent_courses" value={item.sku}  onChange={() => setAgentCourse(item.sku)}/>
                                    {item.name}
                                    </label>
                                </>
                             ))}
                        </div>
                            <button className='modal-continue-button' type="submit" onClick={handleAgentCourseSubmit}>Continue</button>
                        </form>
                        
                    </div>
                </div>
            );
        }
        if (type === "payment_plan") {
            return (
                <div className="ad-modal-overlay">
                    <div className="ad-modal">
                        <button className="adm-close-button" onClick={handleModalClose}>×</button>
                        <img 
                            src={domain_url+'/images/certs/'+id+'.png'} 
                            alt={`${type} badge`} 
                            className="modal-badge" 
                        />
                        <h2>Pick a Payment Plan</h2>
                        
                        <form className='ad-modal-form'>
                            {paymentplanlist.map((item, index) => (
                                <>
                                    <label>
                                    <input type="radio" id="f-option" name="payment_plans" value={index+1} onChange={() => setPaymentPlan(index+1)}/>
                                    {item.name} {item.help_text}<span class="sta-pay">${item.price}</span>
                                    </label>
                                </>
                            ))}
                          
                            <button className='modal-continue-button' type="submit" onClick={handleSubmit}>Continue</button>
                       
                        </form>
                    </div>
                </div>
            );
        }
        if (type === "elective_course") {
            return (
                <div className="ad-modal-overlay">
                    <div className="ad-modal">
                        <button className="adm-close-button" onClick={handleModalClose}>×</button>
                        <img 
                            src={domain_url+'/images/certs/'+id+'.png'} 
                            alt={`${type} badge`} 
                            className="modal-badge" 
                        />
                        <h2>Pick an Elective Course</h2>
                        
                        <form className='ad-modal-form'>
                        <div class="modal-body">
                            {allproducts.map((item, index) => (
                                <>
                                    <label>
                                    <input type="radio" id="f-option" name="only_agent_courses" value={item.sku} onChange={() => setElectiveCourse(item.sku)}/>
                                    {item.name}
                                    </label>
                                </>
                             ))}
                        </div>
                            <button className='modal-continue-button' type="submit" onClick={handleElectiveCourseSubmit}>Continue</button>
                       
                        </form>
                    </div>
                </div>
            );
        }
        if (type === "success") {
            return (
                <div className='ad-modal-overlay'>
                <div className="adv-success-message">
                    <div className='add-sucs'>
                    <p>Added Successfully</p>
                    <button className="ad-sucs-close-button" onClick={handleModalClose}>×</button>
                    </div>
                    <div className="adv-message-content">
                        <div className="adv-message-icon">
                            <i className="fa fa-check-circle"></i>
                        </div>
                        <div className="adv-message-text">
                            {message.map((message, index) => (
                                <p>{message}</p>
                            ))}
             
                        </div>
                    </div>
                    <div className="adv-success-buttons">
                        <Link to="/smww/cart">
                            <button className="adv-view-cart-button">View Cart</button>
                        </Link> 
                        <Link to="/smww/checkout">
                            <button className="adv-checkout-button">Checkout</button>
                        </Link>   
                        <button className="adv-close-button" onClick={handleModalClose}>Close</button>
                    </div>
                </div>
                </div>
            );

        }
        return null;
    };

    return (
        <div className="adv-cert-container">
            <div className="full-width-header">
                <h2>SMWW ADVANCED CERTIFICATES</h2>
            </div>
            <div className="advanced-certificates">
                <div className="adv-cert-header">
                    <h1>Advanced Certificates Benefits</h1>
                </div>
                <div className="adv-cert-content">
                    <div className="adv-cert-text">
                        <p className='description_class'>Our Advanced Certificates are hand-selected by our team of sports industry experts to put you on a path to success.</p>
                        <ul>
                            <li>Stand out with a resume badge</li>
                            <li>Take 5 courses and save up to $2,200</li>
                            <li>Receive a customized wooden plaque</li>
                        </ul>
                        <button className="faq-button">FAQ</button>
                    </div>
                    <div className="adv-cert-image-container">
                        <img
                            src="https://aisiiczsuo.cloudimg.io/v7/https://www.sportsmanagementworldwide.com/images/certs/cert_side.png"
                            alt="Certificate"
                            className="adv-cert-image"
                            onClick={handleImageClick}
                        />
                    </div>
                </div>
            </div>
            {isImageFullScreen && (
                <div className="fullscreen-overlay">
                    <span className="adcert-close-button" onClick={handleCloseClick}>×</span>
                    <img
                        src="https://aisiiczsuo.cloudimg.io/v7/https://www.sportsmanagementworldwide.com/images/certs/cert_side.png"
                        alt="Certificate Fullscreen"
                        className="fullscreen-image"
                    />
                </div>
            )}
            <br /> <br />
            {renderModal(type,packageid)}
            {/* Second Container */}
            <div className="pick-specialty">
                <div className="full-width-header">
                    <h2>Pick Your Specialty</h2>
                </div>
                <div className="specialty-cards">
                    <div className="specialty-card">
                        <div className='card-header'>
                            <h1>ATHLETE <br /> REPRESENTATION</h1>
                        </div>
                        <div className='tile-img'>
                            <img src={`${process.env.PUBLIC_URL}/images/adv-ct/athlete-background.png`}
                            alt="athlete-bgd"  />
                            <img 
                            src={`${process.env.PUBLIC_URL}/images/adv-ct/ath-rep-badge.png`}
                            alt="athlete-badge" 
                            className='badge-img'
                            />
                        </div> 
                        <div className='package-content'>
                            <a href="/content/sports-agent-courses">Any Agent Course</a><br />
                            <a href="/courses/athlete-marketing-and-branding-course">Athlete Marketing and Branding</a><br />
                            <a href="/courses/name-image-likeness">Name, Image, and Likeness</a><br />
                            <a href="/courses/salary-cap-analysis">Salary Cap &amp; Contract Negotiation</a><br />
                            <a href="/courses" style={{ fontStyle: 'italic' }}>and 1 Course of Your Choice</a><br />
                        </div>
                        <button onClick={() => handleModalOpen('agent',415)}>ENROLL TODAY</button>
                        <div className='more_link'>
                            <a href='https://www.sportsmanagementworldwide.com/athlete-representation-certificate'>learn more</a>
                        </div>
                    </div>
                   

                    <div className="specialty-card">
        <div className='card-header'>
          <h1>BASEBALL <br />
          FRONT OFFICE</h1>
        </div>
        <div className='tile-img'>
          <img src={`${process.env.PUBLIC_URL}/images/adv-ct/baseball-background.png`}
          alt="athlete-bgd"  />
          <img 
          src={`${process.env.PUBLIC_URL}/images/adv-ct/baseball-badge.png`}
          alt="athlete-badge" 
          className='badge-img'
          />
        </div> 
        <div className='package-content'>
          <a href="/content/sports-agent-courses">Baseball Agent</a><br />
          <a href="/courses/athlete-marketing-and-branding-course">Baseball Analytics</a><br />
          <a href="/courses/name-image-likeness">Baseball GM &amp; Scouting</a><br />
          <a href="/courses/salary-cap-analysis">Baseball Player Development &amp; Strategy</a><br />
          <a href="/courses" style={{ fontStyle: 'italic' }}>and 1 Course of Your Choice</a><br />
        </div>
        <button onClick={() =>handleModalOpen('agent',255)}>ENROLL TODAY</button>
        <div className='more_link package-content'>
          <a href='https://www.sportsmanagementworldwide.com/athlete-representation-certificate'>learn more</a>
        </div>
      </div>
     

      <div className="specialty-card">
        <div className='card-header'>
          <h1>BASKETBALL <br />
          FRONT OFFICE</h1>
        </div>
        <div className='tile-img'>
          <img src={`${process.env.PUBLIC_URL}/images/adv-ct/basketball-background.png`}
          alt="athlete-bgd"  />
          <img 
          src={`${process.env.PUBLIC_URL}/images/adv-ct/basketball-badge.png`}
          alt="athlete-badge" 
          className='badge-img'
          />
        </div> 
        <div className='package-content'>
          <a href="/content/sports-agent-courses">Basketball Agent</a><br />
          <a href="/courses/athlete-marketing-and-branding-course">Basketball Analytics</a><br />
          <a href="/courses/name-image-likeness">Basketball GM &amp; Scouting</a><br />
          <a href="/courses/salary-cap-analysis">Basketball Player Development &amp; Strategy</a><br />
          <a href="/courses/salary-cap-analysis">Basketball Video Performance Analysis With Synergy</a><br />
         
        </div>
        <button onClick={() => handleModalOpen('payment_plan',258)}>ENROLL TODAY</button>
        <div className='more_link'>
          <a href='https://www.sportsmanagementworldwide.com/athlete-representation-certificate'>learn more</a>
        </div>
      </div>
      

      <div className="specialty-card">
        <div className='card-header'>
          <h1>FOOTBALL <br />
          FRONT OFFICE</h1>
        </div>
        <div className='tile-img'>
          <img src={`${process.env.PUBLIC_URL}/images/adv-ct/football-background.png`}
          alt="athlete-bgd"  />
          <img 
          src={`${process.env.PUBLIC_URL}/images/adv-ct/football-badge.png`}
          alt="athlete-badge" 
          className='badge-img'
          />
        </div> 
        <div className='package-content'>
          <a href="/content/sports-agent-courses">Proficiency in PFF Ultimate</a><br />
          <a href="/courses/athlete-marketing-and-branding-course">Football Analytics</a><br />
          <a href="/courses/name-image-likeness">Football GM &amp; Scouting</a><br />
          <a href="/courses/salary-cap-analysis">Football Player Development &amp; Strategy</a><br />
          <a href="/courses/salary-cap-analysis">Football Video Performance Analysis with Catapult</a><br />
          
        </div>
        <button onClick={() => handleModalOpen('payment_plan',261)}>ENROLL TODAY</button>
        <div className='more_link'>
          <a href='https://www.sportsmanagementworldwide.com/athlete-representation-certificate'>learn more</a>
        </div>
      </div>
     
      <div className="specialty-card">
        <div className='card-header'>
          <h1>HOCKEY <br />
          FRONT OFFICE</h1>
        </div>
        <div className='tile-img'>
          <img src={`${process.env.PUBLIC_URL}/images/adv-ct/hockey-background.png`}
          alt="athlete-bgd"  />
          <img 
          src={`${process.env.PUBLIC_URL}/images/adv-ct/hockey-badge.png`}
          alt="athlete-badge" 
          className='badge-img'
          />
        </div> 
        <div className='package-content'>
          <a href="/courses/hockey-agent">Hockey Agent</a><br />
          <a href="/content/sports-agent-courses">Hockey Analytics</a><br />
          <a href="/courses/athlete-marketing-and-branding-course">Hockey GM &amp; Scouting</a><br />
          <a href="/courses/name-image-likeness">Hockey Player Development & Strategy</a><br />
          <a href="/courses/digital-video-editing-hockey">Hockey Video Performance Analysis With Catapult</a><br />
         
        </div>
        <button onClick={() => handleModalOpen('payment_plan',264)}>ENROLL TODAY</button>
        <div className='more_link'>
          <a href='https://www.sportsmanagementworldwide.com/athlete-representation-certificate'>learn more</a>
        </div>
      </div>
     

      <div className="specialty-card">
        <div className='card-header'>
          <h1>SOCCER <br />
          FRONT OFFICE</h1>
        </div>
        <div className='tile-img'>
          <img src={`${process.env.PUBLIC_URL}/images/adv-ct/soccer-background.png`}
          alt="athlete-bgd"  />
          <img 
          src={`${process.env.PUBLIC_URL}/images/adv-ct/soccer-badge.png`}
          alt="athlete-badge" 
          className='badge-img'
          />
        </div> 
        <div className='package-content'>
          <a href="/content/sports-agent-courses">Soccer Agent</a><br />
          <a href="/courses/athlete-marketing-and-branding-course">Soccer Analytics</a><br />
          <a href="/courses/athlete-marketing-and-branding-course">Soccer Certification Prep</a><br />
          <a href="/courses/name-image-likeness">Soccer Management & Scouting</a><br />
          <a href="/courses/salary-cap-analysis">Soccer Player Development</a><br />
         
        </div>
        <button onClick={() => handleModalOpen('payment_plan',267)}>ENROLL TODAY</button>
        <div className='more_link'>
          <a href='https://www.sportsmanagementworldwide.com/athlete-representation-certificate'>learn more</a>
        </div>
      </div>
      
      </div>       
  </div>
 
            <div className='substitute'>
                <p>*Substitutions are available with permission from your SMWW Career Advisor. 
                Please fill out the form below and our career advisors will assist you.</p>
            </div>

            <div className='low-rate'>
                <div className="full-width-header">
                    <h2>Save and start your career with Advanced Certificates!</h2>
                </div>
                <p><span className='mob-low'>As low as </span> &nbsp; <span className='first_rate'> $9,150 </span> &nbsp; <span className='new-rate'> $5,285</span></p>
            </div>

            <br /> <br />

            {/* Third Container */}
            <div className="linkedin-container">
                <div className="linkedin-info">
                    <h2>Stand Out on LinkedIn</h2>
                    <div className='linkedin-badge-img'>
                        <img 
                        src={`${process.env.PUBLIC_URL}/images/adv-ct/ath-rep-badge.png`}
                        alt="athlete-badge" 
                        />
                    </div>
                    <p>When you earn an Advanced <br />
                        Certificate, you can show it off on your <br /> 
                        LinkedIn profile.</p>
                    <br />
                    <p>Instructions on how to add <br />
                        it are provided with your <br />
                        certificate.</p>
                </div>
                <div className="linkedin-form">
                {formData.showForm &&
                    <div className = "form-wrapper">
                    <h2>Any Questions?</h2>
                    <p>Fill out the form below:</p>
                    <form>
                        <input type="text" placeholder="First name" name="first_name" value={formData.first_name} onChange={handleChange} required/>
                        <input type="text" placeholder="Last name" name="last_name" value={formData.last_name} onChange={handleChange} required/>
                        <input type="email" placeholder="Email" name="email" value={formData.email} onChange={handleChange} required/>
                        <PhoneInput
                            className="number"
                            name="primary_phone"
                            country={"us"}
                            value={formData.primary_phone}
                            onChange={(phone) =>
                            handleChange({ target: { value: phone, name: 'primary_phone' } })
                            }
                        />
                        
                        <Select id="country" name="country" className='select-country' 
                        onChange={(val) => handleChange({ target: { value: val.value, name: 'country' } })} options={countrieslist}
                        placeholder='Select Country'
                        >
                        </Select>
                        {formData.showStateField &&
                            <select  id="state" name="state" value={formData.state} onChange={handleChange}>
                                <option value="">Select State</option>
                                {Object.keys(statelist).map(state => {
                                    return <option key={statelist[state].name} value={statelist[state].name}>{statelist[state].name}</option>
                                })}
                            </select>
                        }
                        {formData.showProvinceField &&
                           
                            <input type="text" id="province" name="province" value={formData.province} onChange={handleChange} placeholder="Province"/>
          
                        }
                        <textarea placeholder="Question" name="question" value={formData.question} onChange={handleChange} required></textarea>
                        <div className="recaptcha">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={sitekey}
                                size="visible"
                                required
                            />
                        </div>
                        <button disabled={disabled} type="submit" onClick={handleFormSubmit}>{buttontext}</button>
                    </form>
                    </div>
                }
                {formData.showSuccessMessage &&
                    <div className="success_message_wrapper">
                        Thank you for your interest!<br/> Someone from SMWW will be contacting you soon.
                    </div>
                }
                </div>
            </div>
        </div>
    );
};

export default AdvancedCertificate;
