import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import { configData } from "../config";
import 'react-quill/dist/quill.snow.css';
import './classes/ReferralEmail.css';

const ReferralEmail = ({email_content,subject,user}) => {
  
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    friendEmail: '',
    senderEmail:user.email,
    subject:subject,
  });
  const [emailcontent, setEmailContent] = useState('');
  const[success,setSuccess] = useState({});
  const app_url = configData.APP_URL;
  
  useEffect(() => {
   
    var editor = document.getElementsByClassName('ql-editor');
    editor[0].innerHTML = email_content;
    
  },[email_content]);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleQuillChange = (value) => {
    setEmailContent(value);
  };

  const handleSubmit  = async event => {
    event.preventDefault();
    const postData = new FormData();
    postData.append('html', emailcontent);
    postData.append('formData', JSON.stringify(formData));
    const response = await fetch(app_url+'referral/sendreferralemail', {
      method: 'POST',
      body: postData,
     
      });
      const data = await response.json();
      if(data.data.msg === "Success") {
        setSuccess(data.data.result);
        setFormData({
          firstName: '',
          lastName: '',
          friendEmail: '',
          senderEmail:user.email,
          subject:subject,
          });
      }
  };

  return (
    <div className="referral-email-form">
      {success.length > 0 && (
          <>
           {success.map((item, index) => {
             return <>
            <div className="set-message-done">
            <span className="cart-message-icon message-icon"><i className="fa fa-check-circle"></i></span> {item}
          </div></>
          
        })}  
          </>
        )}
      <form onSubmit={handleSubmit}>

        <div className='refer-email-name-fl'>
        <div>
          <label htmlFor="firstName">First Name</label>
          <input 
            type="text" 
            id="firstName" 
            name="firstName" 
            value={formData.firstName} 
            onChange={handleChange} 
            required 
          />
        </div>
        <div>
          <label htmlFor="lastName">Last Name</label>
          <input 
            type="text" 
            id="lastName" 
            name="lastName" 
            value={formData.lastName} 
            onChange={handleChange} 
            required 
          />
        </div>
        </div>

        <div>
          <label htmlFor="friendEmail">Friend's Email Address</label>
          <textarea 
            type="email" 
            id="friendEmail" 
            name="friendEmail" 
            value={formData.friendEmail} 
            onChange={handleChange} 
            placeholder="Example: john@company.com"
            required 
          />
        </div>

        <div className='referral-email-form-quill-content'>
          <label htmlFor="message">Message</label>
          <ReactQuill 
            value={emailcontent} 
            onChange={handleQuillChange} 
            style={{backgroundColor:'#fff'}}
            required 
          />
        </div>
        <button className='referral-email-form-button' type="submit">Send Email</button>
      </form>
    </div>
  );
};

export default ReferralEmail;
