import React, { useState } from 'react';
import './classes/RequestForm.css';
import { configData } from "../config";
import { Helmet } from 'react-helmet';

const RequestForm = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    emailAgain: '',
    primary_phone: '',
    field_request_more_info: '',
    pageURL: window.location.href,
  });

  const [errors, setErrors] = useState({});
  const app_url = configData.APP_URL;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.first_name) tempErrors.first_name = 'First Name is required';
    if (!formData.last_name) tempErrors.last_name = 'Last Name is required';
    if (!formData.email) tempErrors.email = 'Email is required';
    if (!formData.emailAgain) tempErrors.emailAgain = 'Email confirmation is required';
    if (formData.email !== formData.emailAgain) tempErrors.emailAgain = 'Emails do not match';
    if (!formData.primary_phone) tempErrors.primary_phone = 'Phone Number is required';
    if (!formData.field_request_more_info) tempErrors.field_request_more_info = 'Location and Comment are required';

    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (validate()) {
      const postData = formData; 
      const response = await fetch(app_url+'requestmoreinfo/application', {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: {
          'Content-Type': 'application/json',
        },
        });
        const data = await response.json();
        if(data.data.result === "user_created") {
          alert("Form submitted");
        }
    }
  };

  return (

    <div className='request-form-container'>
    <form onSubmit={handleSubmit} className='request-info info-form'>
        <Helmet><title>Request More Information</title></Helmet>
        <div className='req-paragraph'>
            <p>Your information will not be sold or shared.  For details see our <a target="_blank" href="https://www.sportsmanagementworldwide.com/privacy">Privacy Statement</a>.</p>
            <p>Fields marked with an asterisk <span className="form-required" title="This field is required.">*</span> are required.</p>
        </div>
        <hr />
      <div className='info-fields'>
        <label>First Name <span className="form-required" title="This field is required.">*</span></label>
        <input type="text" name="first_name" value={formData.first_name} onChange={handleChange} />
        {errors.first_name && <span>{errors.first_name}</span>}
      </div>
      <div className='info-fields'>
        <label>Last Name <span className="form-required" title="This field is required.">*</span></label>
        <input type="text" name="last_name" value={formData.last_name} onChange={handleChange} />
        {errors.last_name && <span>{errors.last_name}</span>}
      </div>
      <div className='info-fields'>
        <label>Email <span className="form-required" title="This field is required.">*</span></label>
        <input type="email" name="email" value={formData.email} onChange={handleChange} />
        {errors.email && <span>{errors.email}</span>}
      </div>
      <div className='info-fields'>
        <label>Email, again <span className="form-required" title="This field is required.">*</span></label>
        <input type="email" name="emailAgain" value={formData.emailAgain} onChange={handleChange} />
        <div class="description">Please confirm your email address by entering it again.</div>
        {errors.emailAgain && <span>{errors.emailAgain}</span>}
      </div>
      <div className='info-fields'>
        <label>Phone Number <span className="form-required" title="This field is required.">*</span></label>
        <input type="tel" name="primary_phone" value={formData.primary_phone} onChange={handleChange} />
        {errors.primary_phone && <span>{errors.primary_phone}</span>}
      </div>
      <div className='info-fields'>
        <label>Your location and Question or Comment <span className="form-required" title="This field is required.">*</span></label>
        <textarea name="field_request_more_info" value={formData.field_request_more_info} onChange={handleChange}></textarea>
        {errors.field_request_more_info && <span>{errors.field_request_more_info}</span>}
        <input type="hidden" name="pageURL" id="pageURL" value={formData.pageURL} onChange={handleChange}/>
      </div>
      <button type="submit" className='info-submit'>Submit</button>
    </form>
    </div> 
  );
};

export default RequestForm;
