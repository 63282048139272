import React, { useState} from 'react';
function MinicartLength({ length }) {
    
    return (
      <>
       <span id="totalItems">{length}</span>
      </>
    );
  }
  export default MinicartLength;
