import React from 'react';
import { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { configData } from "../config";
import './classes/SubscriptionUpdate.css';

const SubscripionUpdate = () => {
  const {subscription_id} = useParams();
  const[userOrderDetails,setUserOrderDetails] = useState({});
  const[error,setError] = useState({});
  const[success,setSuccess] = useState({});

  const uid = localStorage.getItem('uid');
  const app_url = configData.APP_URL;

  const [formData, setFormData] = useState({
    nextBillingDate: '',
    uid:uid,
    subscriptionId: subscription_id
  })
  const handleChange = event => {
    const { name, value} = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }
  useEffect(() => {
   
    retrieveSubscriptionOrderDetails();
    
  },[]);
  async function retrieveSubscriptionOrderDetails() {
    if(subscription_id) {
    const response = await fetch(
      app_url+'authnetsubscription/getnextbillingdatedetails?subscription_id='+subscription_id,
      {
        method: "GET",
  
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const responsedata = await response.json();
    if(responsedata.data.msg === "Success") {
    
      setUserOrderDetails(responsedata.data.result);
    
    } else {
      setError(responsedata.data.result);
    }
    
   
  }
  }
  const validateForm = (data) => {
    const errors = [];
    console.log(data);
    if (!data.nextBillingDate.trim()) {
      
      errors.push("Please Select Date.");
    }
    
    return errors;
  }
  const handleSubmit = async event => {
    event.preventDefault();
    const newErrors = validateForm(formData);
    setError(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const postData = formData; 
      const response = await fetch(app_url+'authnetsubscription/changebillingdate', {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: {
        'Content-Type': 'application/json',
      },
      });
      const responsedata = await response.json();
    if(responsedata.data.msg === "Success") {
    
      setSuccess(responsedata.data.result);
    
    } else {
      setError(responsedata.data.result);
    }
    }
  }

  return (
    <div className="subs-update-container">
      <div className="subs-update-content">
      {error.length > 0 && (
          <div className="set-message-error">
           {error.map((item, index) => {
           return <>{item}<br /></>
          
        })}  
          </div>
        )}
       
         {success.length > 0 && (
          <>
           {success.map((item, index) => {
             return <>
            <div className="set-message-done">
            <span className="cart-message-icon message-icon"><i className="fa fa-check-circle"></i></span> {item}
          </div></>
          
        })}  
          </>
        )}
      <div className="subs-update-header-container">
        <h2>Subscription</h2>
        <a href={"/user/"+uid+"/myorders"}>← Back</a>
      </div>
        
        <table className="cchange-table cchange-table-bordered cchange-table-striped">
          <thead>
            <tr>
              <th width="10%">Order #</th>
              <th width="20%">Product</th>
              <th width="12%">Next Billing Date</th>
              <th width="10%">Amount</th>
              <th width="10%">Card</th>
              <th width="10%">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{userOrderDetails.order_id}</td>
              <td>{userOrderDetails.product_title}</td>
              <td>{userOrderDetails.next_billing_date}</td>
              <td>${userOrderDetails.subscription_amount}</td>
              <td>{userOrderDetails.card_num}</td>
              <td>{userOrderDetails.sub_status}</td>
            </tr>
          </tbody>
        </table>

        <h2>Change Billing Date</h2>
        <form className="credit-card-form">
        <div className="cchange-form-group">
            <label>Next Billing Date *</label>
            <input className='cc-edit-owner' name="nextBillingDate" type="date" onChange={handleChange}/>
          </div>
         
          <div className="cchange-form-actions">
            <button className='cchange-submit' type="submit" onClick={handleSubmit}>Change</button>
            <button className='cchange-maybelater' type="button">Maybe Later!</button>
          </div>
        </form>
      </div>
      <div className="cchange-quick-links">
        <h3>Quick Links</h3>
        <ul>
          <li><Link to="/dashboard">Dashboard Home</Link></li>
          <li><Link to="/manage-profile">Manage My Profile</Link></li>
          <li><Link to="/account">My Account</Link></li>
          <li><Link to="/people">People</Link></li>
          <li><Link to="/add-application">Add Application</Link></li>
        </ul>
      </div>
    </div>
  );
};

export default SubscripionUpdate;
