import React from 'react';
import PostList from './PostList';

function App() {
  const posts = [
    { id: 1, title: 'Post 1' },
    { id: 2, title: 'Post 2' },
    { id: 3, title: 'Post 3' },
  ];

  return (
    <div>
      <h1>My Blog</h1>
      <PostList posts={posts} />
    </div>
  );
}

export default App;
